import { useApp } from '../../../hooks';
import { decodeId } from '../../../utils';
import moment from 'moment';
import { cva } from 'class-variance-authority';

export default function AllotmentsEditorRoomDay({ room, date, onClick, selected }) {
  const { currentOrganizationId } = useApp();

  const organizationId = decodeId(currentOrganizationId);
  const organizationAllotments = room.organizations?.find(
    (org) => parseInt(org.id) === organizationId
  );
  const allotmentDay = organizationAllotments?.allotmentDays?.find(
    (aDay) => {
      return moment(aDay.day).isSame(date, 'day');
    }
  );

  const dayBadge = cva([
    'w-12 h-5 rounded border border-gray-200',
    'flex justify-center items-center text-white',
  ], {
    variants: {
      state: {
        'on_request': 'bg-k-orange',
        'stop_sale': 'bg-red-500',
        'free_sale': 'bg-emerald-400',
        allotment: 'bg-green-600',
      },
      selected: {
        true: 'ring-2 ring-offset-1 ring-blue-500',
        false: 'hover:ring-2 ring-blue-400',
      }
    }
  });

  return (
    <div
      onClick={onClick}
      className={dayBadge({ state: allotmentDay?.state, selected })}
    >
      {selected && (
        <i className="far fa-check" />
      )}
    </div>
  );
}
