import { useTranslation } from 'react-i18next';
import { Button } from 'components';
import { useEffect, useState } from 'react';
import Header from './Partials/Header';
import { get, post, put } from '../../../api/node';
import { useApp, useError, useSnack } from '../../../hooks';
import { useAsync } from 'react-async';
import { useNavigate, useParams } from 'react-router-dom';
import { MdRemoveRedEye } from 'react-icons/md';
import { isEqual } from 'lodash';
import DistributionBrowser from './Partials/DistributionBrowser';
import DistributionSelected from './Partials/DistributionSelected';
import { Input } from 'ui';
import { Spinner } from '@koob/margaret';

const getList = async ({ filters }) => {
  return await post('/trip/templates', filters);
};

const getRequest = async ({ requestId }) => {
  return await get(`ToConnectionRequest/${requestId}`);
};

const ToConnectionRequestTemplate = () => {
  const { t } = useTranslation('toConnectionRequest');
  const { notify } = useSnack();
  const { sendErrorSnack } = useError();
  const { requestId } = useParams();
  const navigate = useNavigate();
  const { isAllowedTo, canManageToConnectionRequest, user } = useApp();

  const isAllowedToManage = () => {
    return (
      isAllowedTo('canManageToConnectionRequest') ||
      canManageToConnectionRequest(user)
    );
  };

  const { data, isLoading } = useAsync({
    promiseFn: getRequest,
    requestId: requestId,
  });
  const request = data?.data;
  const [filters, setFilters] = useState({});
  const [page, setPage] = useState(1);
  const limitPerPage = 25;

  const formatTemplateData = listTemplate => {
    return (listTemplate || []).map(node => {
      if (node?.tripTemplate) {
        return {
          id: node?.tripTemplate?.id,
          name: node?.tripTemplate?.name,
          tripDuration: node.tripTemplate?.trips?.[0]?.duration,
          margin: node.margin,
          isMarginPercent: node.isMarginPercent,
        };
      } else {
        return {
          id: node?.id,
          name: node?.name,
          tripDuration: node?.trips?.[0]?.duration,
          margin: null,
          isMarginPercent: null,
        };
      }
    });
  };

  useEffect(() => {
    if (request) {
      setGlobalMargin(request.margin ?? 0);
      setIsGlobalMarginPercent(request.isMarginPercent ?? false);
    }
    if (request?.requestTemplate) {
      let formattedTemplate = formatTemplateData(request?.requestTemplate);

      setSelectedTemplates(formattedTemplate);
    }
  }, [request]);

  const requestFilters = {
    search:
      filters?.location?.kind === 'template' ? filters?.location?.title : '',
    state: 'available',
    page: page,
    perPage: limitPerPage,
  };

  const { data: templatesList, reload } = useAsync({
    promiseFn: getList,
    filters: requestFilters,
    watchFn: (props, prevProps) => {
      return !isEqual(props.filters, prevProps.filters);
    },
  });

  const [templates, setTemplates] = useState([]);
  const [selectedTemplates, setSelectedTemplates] = useState(
    request?.templates || [],
  );
  const [globalMargin, setGlobalMargin] = useState(0);
  const [isGlobalMarginPercent, setIsGlobalMarginPercent] = useState(false);
  const [saving, setSaving] = useState(false);

  const save = async (state, comment) => {
    try {
      setSaving(true);
      await put(`ToConnectionRequest/${requestId}`, {
        margin: globalMargin ? parseInt(globalMargin) : 0,
        isMarginPercent: isGlobalMarginPercent,
        state: state ?? request.state,
        explaination: comment?.reason,
        toConnectionRequestTemplates: selectedTemplates.map(template => ({
          id: template.id,
          margin: template.margin ? parseInt(template.margin) : 0,
          isMarginPercent: template.isMarginPercent,
        })),
      });

      notify(t('updated'));

      navigate(-1);
    } catch (error) {
      sendErrorSnack(error);
    } finally {
      setSaving(false);
    }
  };

  const setTemplateAttribute = (templateId, attribute, value) => {
    setSelectedTemplates(current =>
      current.map(template => {
        if (template.id === templateId) {
          return {
            ...template,
            [attribute]: value,
          };
        }
        return template;
      }),
    );
  };

  const isTemplateSelected = templateId => {
    return selectedTemplates.some(e => e.id === templateId);
  };

  const unselectTemplate = templateId => {
    setSelectedTemplates(selectedTemplates.filter(e => e.id !== templateId));
  };

  const onAddTemplate = template => {
    if (!isTemplateSelected(template.id)) {
      template.margin = globalMargin;
      template.isMarginPercent = isGlobalMarginPercent;
      setSelectedTemplates([...selectedTemplates, template]);
    } else {
      unselectTemplate(template.id);
    }
  };

  const selectAllShownTemplates = templateList => {
    const templatesNotSelected = templateList.filter(
      template => !isTemplateSelected(template.id),
    );
    templatesNotSelected.forEach(template => {
      template.margin = globalMargin;
      template.isMarginPercent = isGlobalMarginPercent;
    });
    setSelectedTemplates([...selectedTemplates, ...templatesNotSelected]);
  };

  const BrowserHeadings = [
    {
      slug: 'name',
      label: t('experienceName'),
      width: '90px',
      cannotBeReordered: true,
    },
    {
      slug: 'tripDuration',
      label: t('tripDuration'),
      width: '60px',
      cannotBeReordered: true,
    },
    {
      slug: 'selected',
      label: t('enabled'),
      width: '50px',
      cannotBeReordered: true,
    },
  ];

  const selectedDatasHeadings = [
    {
      slug: 'name',
      label: t('name'),
      width: '80px',
      cannotBeReordered: true,
    },
    {
      slug: 'tripDuration',
      label: t('tripDuration'),
      width: '60px',
      cannotBeReordered: true,
    },
    {
      slug: 'margin',
      label: t('margin'),
      width: '40px',
      cannotBeReordered: true,
    },
    {
      slug: 'isMarginPercent',
      label: t('percent'),
      width: '60px',
      cannotBeReordered: true,
    },
    {
      slug: 'selected',
      label: t('enabled'),
      width: '40px',
      cannotBeReordered: true,
    },
  ];

  const templateSelectedDataTable = (selectedTemplates || []).map(node => ({
    name: {
      value: node?.name,
    },
    tripDuration: {
      value: node?.tripDuration,
    },
    margin: {
      render: () => (
        <Input
          type="number"
          value={node?.margin}
          onChange={e =>
            setTemplateAttribute(node.id, 'margin', e.target.value)
          }
          disabled={!isAllowedToManage()}
        />
      ),
    },
    isMarginPercent: {
      render: () => (
        <input
          type="checkbox"
          checked={node?.isMarginPercent}
          onChange={() =>
            setTemplateAttribute(
              node.id,
              'isMarginPercent',
              !node.isMarginPercent,
            )
          }
          disabled={!isAllowedToManage()}
        />
      ),
    },
    selected: {
      render: () =>
        isAllowedToManage() ? (
          <button
            className="border border-gray-200 rounded-full p-2 text-lg leading-none"
            onClick={() => unselectTemplate(node.id)}
          >
            <MdRemoveRedEye className="text-orange-500" />
          </button>
        ) : null,
    },
  }));

  const tableTemplates = (templates || []).map(node => ({
    id: node?.id,
    name: {
      value: node?.name,
    },
    tripDuration: {
      value: node?.tripDuration,
    },
    selected: {
      render: isAllowedToManage()
        ? () => (
            <button
              className="border border-gray-200 rounded-full p-2 text-lg leading-none"
              onClick={() => {
                onAddTemplate(node);
              }}
            >
              {isTemplateSelected(node?.id) ? (
                <MdRemoveRedEye className="text-orange-500" />
              ) : (
                <MdRemoveRedEye className="text-gray-400" />
              )}
            </button>
          )
        : null,
    },
  }));

  return (
    <>
      {isLoading ? (
        <Spinner />
      ) : (
        <div className="px-4">
          <Header request={request} save={save} isLoading={saving} />

          {isAllowedToManage() && (
            <div className="my-5 flex justify-end gap-5">
              <Button type="button" variant="simple" to={-1}>
                {t('misc:cancel')}
              </Button>
              <Button
                onClick={() => save()}
                variant="primary"
                disabled={saving}
              >
                {t('misc:save')}
              </Button>
            </div>
          )}

          <div className="grid sm:grid-cols-2 gap-5">
            <DistributionSelected
              selectedDatasHeadings={selectedDatasHeadings}
              selectedDatas={templateSelectedDataTable}
              tabTitle={'selectedTemplate'}
            />

            {isAllowedToManage() && (
              <DistributionBrowser
                browserDatas={templates}
                setBrowerDatas={setTemplates}
                selectAllShownItems={selectAllShownTemplates}
                dataTableDatas={tableTemplates}
                BrowserHeadings={BrowserHeadings}
                browserDatasList={templatesList}
                globalMargin={globalMargin}
                setGlobalMargin={setGlobalMargin}
                isGlobalMarginPercent={isGlobalMarginPercent}
                setIsGlobalMarginPercent={setIsGlobalMarginPercent}
                formatBrowerData={formatTemplateData}
                setFilters={setFilters}
                setPage={setPage}
                filters={filters}
                limitPerPage={25}
                reload={reload}
                tabTitle={'browseTemplate'}
                kind={'template'}
                searcTranslate={'toConnectionRequest'}
              />
            )}
          </div>
        </div>
      )}
    </>
  );
};

export default ToConnectionRequestTemplate;
