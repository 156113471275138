import { useRef } from 'react';
import { gql, useQuery } from '@apollo/client';
import styled from 'styled-components';
import { useDeepCompareEffect } from 'react-use';
import { Button } from 'components';
import { useSearchParams } from 'hooks';
import { LocationSearchableSelect, StarsPicker } from 'components/Fields';
import { Stack, Dropdown, PopoverMenu } from '@koob/margaret';
import { MdFilterList } from 'react-icons/md';
import { useTranslation } from 'react-i18next';
import LocationAwareSelect from 'components/LocationAwareSelect';

const FiltersPopoverMenu = styled(PopoverMenu)`
  overflow: visible !important;
  width: 400px;
  max-height: inherit;
  position: relative;
  left: -200px;
`;

const FilterWrapper = styled(Stack).attrs({
  alignY: 'center',
  gutterSize: 1.5,
  paddingRight: 1,
  paddingLeft: 1,
})``;

const GET_KINDS = gql`
  query kinds($order: KindOrderCriteria) {
    kinds(order: $order) {
      edges {
        node {
          id
          displayName
        }
      }
    }
  }
`;

const GET_LOCATIONS_SEARCH = gql`
  query locationsSearch($search: String!) {
    locationsSearch(search: $search) {
      cities {
        nodes {
          id
          title
        }
      }
      countries {
        nodes {
          id
          title
        }
      }
      regions {
        nodes {
          id
          title
        }
      }
    }
  }
`;

const FilterHotels = ({ showLocation = true, showKind = true }) => {
  const { t } = useTranslation('bookings');
  const filtersDropdownRef = useRef();
  const [searchParams, setSearchParams] = useSearchParams();
  const stars = Boolean(searchParams.stars) ? Number(searchParams.stars) : null;
  const { data: kindsData } = useQuery(GET_KINDS);

  useDeepCompareEffect(() => {
    if (Boolean(filtersDropdownRef.current)) {
      filtersDropdownRef.current.close();
    }
  }, [{ ...searchParams }]);

  return (
    <Dropdown
      ref={filtersDropdownRef}
      trigger={
        <Button variant="simple" as="span">
          <Stack alignY="center" gutterSize={0.5}>
            <Stack>
              <MdFilterList />
            </Stack>
            <span>{t('filters')}</span>
          </Stack>
        </Button>
      }
    >
      <FiltersPopoverMenu>
        <FilterWrapper style={{ paddingTop: 30 }}>
          <p style={{ width: 150 }}>{t('stars')}</p>
          <Stack size="full" style={{ margin: 0 }}>
            <StarsPicker
              value={stars}
              onChange={value =>
                setSearchParams({
                  ...searchParams,
                  stars: value,
                })
              }
              shouldShowInactiveStars
            />
          </Stack>
        </FilterWrapper>

        {showKind && (
          <FilterWrapper>
            <p style={{ width: 150 }}>{t('kindOfHotel')}</p>
            <Stack size="full" style={{ margin: 0 }}>
              <LocationAwareSelect
                debounceTime={0}
                name="kindsIds"
                options={kindsData?.kinds?.edges?.map(({ node }) => ({
                  label: node?.displayName,
                  value: node?.id,
                }))}
                renderSelectedValue={value => value?.displayName}
                renderOption={value => value?.label}
                multiple
                shouldHideSelectedValues
                shouldStoreWholeObject
              />
            </Stack>
          </FilterWrapper>
        )}

        {showLocation && (
          <FilterWrapper>
            <p style={{ width: 150 }}>{t('location_hotel')}</p>
            <Stack size="full" style={{ margin: 0 }}>
              <LocationSearchableSelect
                query={GET_LOCATIONS_SEARCH}
                name="searchLocation"
                onChange={value => {
                  setSearchParams({
                    ...searchParams,
                    searchLocation: value,
                  });
                }}
                pathToEdges={['locationsSearch']}
                renderSelectedOption={value => value?.title}
              />
            </Stack>
          </FilterWrapper>
        )}

        <Stack
          alignX="flex-end"
          alignY="baseline"
          gutterSize={1}
          paddingTop={1}
          paddingRight={1}
          paddingBottom={1}
        >
          <Button variant="simple" onClick={() => setSearchParams()}>
            {t('resetFilters')}
          </Button>
          <Button
            variant="primary"
            onClick={() => {
              if (Boolean(filtersDropdownRef.current)) {
                filtersDropdownRef.current.close();
              }
            }}
          >
            {t('misc:search')}
          </Button>
        </Stack>
      </FiltersPopoverMenu>
    </Dropdown>
  );
};

export default FilterHotels;
