import moment from 'moment';
import { useTranslation } from 'react-i18next';
import { useNavigate } from 'react-router-dom';
import { markAsRead } from 'api/node/notifications';
import { getTripConversation } from 'api/node/tripConversation';
import { Popover } from '@headlessui/react';
import { useNotifications } from 'hooks';

const BellIconLabel = ({ children }) => {
  return (
    <div className="absolute inline-flex items-center justify-center w-6 h-6 text-xs font-medium text-white bg-orange-500 border-2 border-white rounded-full -top-2 -right-2">
      {children}
    </div>
  );
};

const NotificationsDropdown = () => {
  const { t } = useTranslation('notifications');
  const navigate = useNavigate();

  const { notifications, reloadNotifications, loading } = useNotifications();

  const onNotificationClick = async notification => {
    try {
      await markAsRead({ notificationId: notification.id });

      let relatedId = notification.relatedObject;

      if (notification.notificationType === 'trip_help_requested') {
        const response = await getTripConversation({
          conversationId: notification.relatedObject,
        });
        relatedId = response?.data?.trip;
      }

      reloadNotifications();
      navigate(linkByType[notification.notificationType] + relatedId);
    } catch (error) {}
  };

  const unreadNotificationsCount = notifications?.filter(
    n => n.read === false,
  ).length;

  const iconByType = {
    trip_help_requested: 'far fa-hands-helping',
    trip_help_assistant: 'fa-regular fa-head-side-headphones',
    trip_new_version_available: 'fa-regular fa-download',
    trip_booking_confirmed: 'fa-regular fa-bags-shopping',
  };

  const linkByType = {
    trip_help_requested: '/trip-designer/library/drafts/',
    trip_help_assistant: '/trip-designer/library/drafts/',
    trip_new_version_available: '/trip-designer/library/drafts/',
    trip_booking_confirmed: '/trip-designer/library/trips/',
  };

  return (
    <Popover className="relative">
      <Popover.Button className="border-none focus:outline-none">
        <div className="relative">
          <i className="far fa-bell text-3xl text-gray-400 hover:text-gray-500 transition" />
          {loading && (
            <BellIconLabel>
              <i className="far fa-refresh animate-spin" />
            </BellIconLabel>
          )}
          {unreadNotificationsCount > 0 && (
            <BellIconLabel>{unreadNotificationsCount}</BellIconLabel>
          )}
        </div>
      </Popover.Button>

      <Popover.Panel className="fixed right-20 top-20 z-10">
        {({ close }) => (
          <div className="bg-white h-72 w-[500px] overflow-y-scroll rounded-md shadow-k p-3">
            {notifications?.length > 0 ? (
              <div>
                {notifications?.map(notification => (
                  <div
                    key={notification.id}
                    className="border-b cursor-pointer"
                    onClick={() => {
                      onNotificationClick(notification);
                      close();
                    }}
                  >
                    <div className="hover:bg-gray-50 py-3 px-4 transition rounded">
                      <div className="flex space-x-3 items-center">
                        <div className="w-8">
                          <i
                            className={[
                              iconByType[notification.notificationType],
                              'text-2xl',
                              notification.read
                                ? 'text-gray-400'
                                : 'text-orange-500',
                            ].join(' ')}
                          />
                        </div>

                        <div className="w-full flex justify-between items-center">
                          <div className="text-sm font-medium text-gray-700">
                            {t('types.' + notification.notificationType)}
                          </div>

                          <div className="text-xs text-gray-400">
                            {moment(notification.createdAt).fromNow()}
                          </div>
                        </div>
                      </div>
                    </div>
                  </div>
                ))}
              </div>
            ) : (
              <div className="h-full flex justify-center items-center">
                <div className="text-gray-400 text-center">
                  <i className="far fa-inbox text-4xl mb-3" />
                  <div className="text-lg">{t('emptyState.title')}</div>
                  <div className="text-sm">{t('emptyState.description')}</div>
                </div>
              </div>
            )}
          </div>
        )}
      </Popover.Panel>
    </Popover>
  );
};

export default NotificationsDropdown;
