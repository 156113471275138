import AllotmentsEditorRoomDay from './AllotmentsEditorRoomDay';
import moment from 'moment';

export default function AllotmentsEditorRoom({
  room,
  currentMonth,
  daysInMonth,
  toggleSelect,
  isSelected,
}) {
  return (
    <div className="py-4 px-4">
      <div className="flex space-x-3 items-center">
        <div className="w-52 font-medium">
          {room.name}
        </div>

        {[...Array(daysInMonth)].map((_, i) => {
          const date = moment().month(currentMonth).date(i + 1);

          return (
            <AllotmentsEditorRoomDay
              key={i}
              room={room}
              date={date}
              onClick={() => toggleSelect(date, room)}
              selected={isSelected(date, room)}
            />
          );
        })}
      </div>
    </div>
  );
}
