import KoobTitle from '../../../../components/Koob/KoobTitle';
import BulkEditorItem from './BulkEditorItem';
import KoobPlaceholder from '../../../../components/Koob/KoobPlaceholder';
import { useTranslation } from 'react-i18next';
import KoobButton from '../../../../components/Koob/KoobButton';
import KoobBadge from '../../../../components/Koob/KoobBadge';
import React, { useMemo, useState } from 'react';
import { useField } from 'formik';
import BulkEditorUpdateMargin from './BulkEditorUpdateMargin';
import { SegmentedControl } from '@koob/margaret';

export default function BulkEditor({ title, filters, loading, results, type }) {
  const { t } = useTranslation('toConnectionRequest');

  const [showOnlySelected, setShowOnlySelected] = useState(null);

  const fieldName = useMemo(() => {
    switch (type) {
      case 'hotel':
        return 'toConnectionRequestHotels';
      case 'experience':
        return 'toConnectionRequestExperiences';
      case 'template':
        return 'toConnectionRequestTemplates';
      default:
        return '';
    }
  }, [type]);

  const [{ value: items }, , { setValue: setItems }] = useField(
    `request.${fieldName}`,
  );

  const filteredResults = useMemo(() => {
    if (showOnlySelected === null) {
      return results;
    }

    return results.filter(result => {
      const findIndex = items.findIndex(item => item.id === result.id);

      return showOnlySelected ? findIndex !== -1 : findIndex === -1;
    });
  }, [results, items, showOnlySelected]);

  const selectAll = () => {
    let newItems = items;

    results?.forEach(result => {
      const findIndex = items.findIndex(
        selectedItem => selectedItem.id === result.id,
      );

      if (findIndex === -1) {
        newItems.push({
          id: result.id,
          isMarginPercent: null,
          margin: null,
          distribute: null,
        });
      }
    });

    setItems(newItems);
  };

  const deselectAll = () => {
    const newItems = items.filter(
      item => !results.some(result => item.id === result.id),
    );

    setItems(newItems);
  };

  return (
    <div className="flex-col space-y-3">
      <div className="grid grid-cols-2 gap-5">
        <div>
          <KoobTitle>{title}</KoobTitle>

          <div className="mt-3 flex space-x-3 items-stretch">{filters}</div>

          <div className="pt-5 flex space-x-3">
            <KoobButton
              onClick={() => selectAll()}
              size="sm"
              disabled={
                !results?.some(
                  result => !items.some(item => item.id === result.id),
                )
              }
            >
              {t('bulkEdit.requestSelector.selectAll')}
            </KoobButton>

            <KoobButton
              onClick={() => deselectAll()}
              size="sm"
              disabled={
                !results?.some(result =>
                  items.some(item => item.id === result.id),
                )
              }
            >
              {t('bulkEdit.requestSelector.deselectAll')}
            </KoobButton>

            <KoobBadge color="gray" size="sm">
              {t('bulkEdit.requestSelector.selectedCount', {
                count: items.length,
              })}
            </KoobBadge>

            <SegmentedControl
              options={[
                { label: t('bulkEdit.editor.filters.all'), value: null },
                { label: t('bulkEdit.editor.filters.selected'), value: true },
                {
                  label: t('bulkEdit.editor.filters.notSelected'),
                  value: false,
                },
              ]}
              value={showOnlySelected}
              onSelect={value => setShowOnlySelected(value)}
            />
          </div>
        </div>

        <BulkEditorUpdateMargin fieldName={fieldName} />
      </div>

      <div className="h-[80vh] overflow-scroll bg-gray-50 p-5 rounded-md">
        {filteredResults?.length > 0 || loading ? (
          <div className="flex-col space-y-3">
            {loading && (
              <>
                <KoobPlaceholder className="h-28 w-full rounded-md" />
                <KoobPlaceholder className="h-28 w-full rounded-md" />
                <KoobPlaceholder className="h-28 w-full rounded-md" />
                <KoobPlaceholder className="h-28 w-full rounded-md" />
                <KoobPlaceholder className="h-28 w-full rounded-md" />
                <KoobPlaceholder className="h-28 w-full rounded-md" />
              </>
            )}

            {!loading &&
              filteredResults?.map(result => (
                <BulkEditorItem key={result.id} item={result} type={type} />
              ))}
          </div>
        ) : (
          <div className="h-96 flex flex-col justify-center items-center">
            <div className="text-center">
              <i className="far fa-search text-4xl text-gray-300" />

              <div className="mt-3 font-medium text-gray-500">
                {t('bulkEdit.editor.noResults')}
              </div>
              <div className="text-sm text-gray-500">
                {t('bulkEdit.editor.noResultsDescription')}
              </div>
            </div>
          </div>
        )}
      </div>
    </div>
  );
}
