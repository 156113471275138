import { useTranslation } from 'react-i18next';
import BulkEditor from './BulkEditor';
import LocationAwareSearch from '../../../../components/LocationAwareSearch';
import React, { useState } from 'react';
import { useSearchParams } from '../../../../hooks';
import { useAsync } from 'react-async';
import { isEqual } from 'lodash';
import { post } from '../../../../api/node';
import { SegmentedControl } from '@koob/margaret';

const getList = async ({ filters }) => {
  return await post('/experiences/liste', filters);
};

export default function BulkEditorExperiences() {
  const { t } = useTranslation('toConnectionRequest');
  const [searchParams] = useSearchParams();

  const [type, setType] = useState(null);

  const requestFilters = {
    search: searchParams.search ?? '',
    type: type,
  };

  const { data, isLoading: loading } = useAsync({
    promiseFn: getList,
    filters: requestFilters,
    watchFn: (props, prevProps) => {
      return !isEqual(props.filters, prevProps.filters);
    },
  });

  return (
    <BulkEditor
      title={t('bulkEdit.editor.experiencesTitle')}
      filters={
        <div className="flex-col space-y-3">
          <LocationAwareSearch name="search" />

          <SegmentedControl
            options={[
              { label: t('experiences:state.all'), value: null },
              { label: t('experiences:transfer'), value: 'Transfer' },
              { label: t('experiences:types.activity'), value: 'Activity' },
              { label: t('experiences:types.extra'), value: 'Extra' },
              { label: t('experiences:types.program'), value: 'Program' },
            ]}
            value={type}
            onSelect={value => setType(value)}
          />
        </div>
      }
      loading={loading}
      results={
        data?.data?.map(experience => ({
          id: experience.id,
          image: experience.imageUrl,
          name: experience.name,
          description: experience.experienceType,
        })) ?? []
      }
      type="experience"
    />
  );
}
