import AllotmentsEditorHeader from './AllotmentsEditorHeader';
import AllotmentsEditorRoom from './AllotmentsEditorRoom';
import KoobPlaceholder from '../../../components/Koob/KoobPlaceholder';
import { useField } from 'formik';
import AllotmentsEditorEditButton from './AllotmentsEditorEditButton';

export default function AllotmentsEditorBody({ initialValues, loading, currentMonth, daysInMonth, selectedDays, setSelectedDays, reload }) {
  const [{ value: rooms }] = useField('');

  const isDaySelected = (date, room) => {
    return selectedDays.some(d => d.roomId === room.id && d.day === date.format('YYYY-MM-DD'));
  };

  const toggleSelectDay = (date, room) => {
    const formattedDate = date.format('YYYY-MM-DD');

    if (isDaySelected(date, room)) {
      setSelectedDays(
        selectedDays.filter(d => d.day !== formattedDate || d.roomId !== room.id)
      );
    } else {
      setSelectedDays([
        ...selectedDays,
        { day: formattedDate, roomId: room.id }
      ]);
    }
  };

  const updatedDays = initialValues?.map(item => {
    const findRoom = (rooms ?? []).find(room => room.id === item.id);
    return {
      ...item,
      organizations: findRoom ? findRoom?.organizations : item?.organizations
    }
  });

  return (
    <div className="my-3">
      <div className="bg-gray-50 border-t border-x border-gray-200 rounded-t-md">
        <AllotmentsEditorEditButton
          selectedDays={selectedDays}
          onApply={() => setSelectedDays([])}
          updatedDays={updatedDays}
        />
      </div>

      <div className="overflow-x-auto rounded-md rounded-t-none border border-gray-200 overflow-hidden">
        <div className="min-h-[25vh] inline-block min-w-full">
          <div className="min-w-full divide-y divide-gray-200">
            <AllotmentsEditorHeader
              currentMonth={currentMonth}
              daysInMonth={daysInMonth}
              updatedDays={updatedDays}
            />

            {!loading ? (
              updatedDays?.map(room => (
                <AllotmentsEditorRoom
                  key={room.id}
                  room={room}
                  currentMonth={currentMonth}
                  daysInMonth={daysInMonth}
                  toggleSelect={toggleSelectDay}
                  isSelected={isDaySelected}
                  reload={reload}
                />
              ))
            ) : (
              <KoobPlaceholder
                className="w-full h-16"
                count={3}
              />
            )}
          </div>
        </div>
      </div>
    </div>
  )
}
