import { get, patch } from './';

export const getHotel = async ({ hotelId, skip, locale }) => {
  if (skip) {
    return Promise.resolve(null);
  }

  return get(`/hotels/${hotelId}?locale=${locale ?? 'en'}`);
};

export const getHotelAllotments = async ({ hotelId, from, to }) => {
  return get(`/hotels/${hotelId}/allotments?from=${from}&to=${to}`);
};

export const updateHotelAllotments = async ({ hotelId, payload }) => {
  return patch(`/hotels/${hotelId}/allotments`, payload);
};

/**
 *
 * @param {string} hotelId
 * @param {UpdateTimezoneRequest} timezone
 */
export const updateHotelTimezone = async (hotelId, timezone) => {
  return patch(`/hotels/${hotelId}/timezone`, {
    timezone: timezone.timezone,
  });
};
