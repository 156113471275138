import KoobModal from '../../../components/Koob/KoobModal';
import { useFormikContext } from 'formik';
import KoobInputCheckbox from '../../../components/Koob/Input/KoobInputCheckbox';
import { useEffect, useState } from 'react';
import KoobTitle from '../../../components/Koob/KoobTitle';
import KoobInput from '../../../components/Koob/Input/KoobInput';
import KoobButton from '../../../components/Koob/KoobButton';
import KoobInputSelect from '../../../components/Koob/Input/KoobInputSelect';
import { useTranslation } from 'react-i18next';
import { applyAllotmentEdits } from './allotmentsUtils';

export default function AllotmentsEditorEditModal({ open, onClose, onApply, selectedDays, updatedDays: rooms }) {
  const { t } = useTranslation('allotments');
  const { setValues } = useFormikContext();

  const [selectedOrganizations, setSelectedOrganizations] = useState([]);
  const [state, setState] = useState(null);
  const [count, setCount] = useState(null);

  const daysGroupedByRoom = selectedDays?.reduce((acc, curr) => {
    if (!acc[curr.roomId]) {
      acc[curr.roomId] = [];
    }

    acc[curr.roomId].push(curr.day);

    return acc;
  }, {});

  const selectedRooms = Array.from(
    new Set(
      selectedDays.map(day => day.roomId)
    )
  ).map(id => rooms.find(room => room.id === id));

  const organizations = Array.from(
    new Set(
      rooms?.flatMap(room => room.organizations.map(org => org.id))
    )
  ).map(id => {
    const org = rooms.flatMap(room => room.organizations).find(org => org.id === id);

    return {
      id: org.id,
      name: org.name,
      kind: org.kind,
    };
  }).sort((a, b) => a.kind === 'dmc' ? -1 : 1);

  const apply = () => {
    const newRooms = applyAllotmentEdits({
      rooms,
      selectedRooms,
      selectedOrganizations,
      selectedDays,
      state,
      count
    });

    setValues(newRooms);

    reset();
    onClose();
    onApply();
  };

  const reset = () => {
    setSelectedOrganizations([]);
    setState(null);
    setCount(null);
  };

  useEffect(() => {
    if (open) {
      setSelectedOrganizations([
        ...organizations.filter(org => org.kind === 'dmc')
      ]);
    }
  }, [open]);

  return (
    <KoobModal
      open={open}
      setOpen={() => onClose()}
      widthClass="sm:max-w-3xl"
    >
      <div className="p-5 flex-col space-y-8">
        <div>
          <KoobTitle>
            {t('modal.roomsTitle')}
          </KoobTitle>

          <div className="mt-5 flex-col space-y-3">
            {selectedRooms?.map(room => (
              <div className="font-medium text-gray-500">
                <div className="flex space-x-1.5">
                  <div>
                    <i className="far fa-bed mr-1.5" />
                    {room.name}
                  </div>

                  <div>
                    &bull;
                  </div>

                  <div>
                    {daysGroupedByRoom[room.id].length} days
                  </div>
                </div>
              </div>
            ))}
          </div>
        </div>

        <div>
          <KoobTitle>
            {t('modal.organizationsTitle')}
          </KoobTitle>

          <div className="mt-5 grid grid-cols-3 gap-5">
            {organizations?.map(org => (
              <KoobInputCheckbox
                key={org.id}
                value={selectedOrganizations?.some(o => o.id === org.id)}
                setValue={() => setSelectedOrganizations(
                  selectedOrganizations?.some(o => o.id === org.id)
                    ? selectedOrganizations.filter(o => o.id !== org.id)
                    : [...selectedOrganizations, org]
                )}
              >
                <div className="flex space-x-2 items-center">
                  <div className="leading-none text-sm text-gray-600 font-medium">
                    {org.kind === 'dmc' ? (
                      <div className="bg-k-orange px-3 py-1.5 text-white rounded-md">
                        <i className="far fa-building mr-1.5" />
                        {t('modal.dmc')}
                      </div>
                    ) : (
                      <div>{org.name}</div>
                    )}
                  </div>
                </div>
              </KoobInputCheckbox>
            ))}
          </div>
        </div>

        <div>
          <KoobTitle>
            {t('modal.allotmentsTitle')}
          </KoobTitle>

          <div className="mt-5 flex space-x-3">
            <KoobInputSelect
              label={t('modal.state.label')}
              value={state}
              onChange={value => setState(value)}
              options={[
                { label: '', value: null },
                { label: t('modal.state.freeSales'), value: 'free_sale' },
                { label: t('modal.state.stopSales'), value: 'stop_sale' },
                { label: t('modal.state.onRequest'), value: 'on_request' },
                { label: t('modal.state.allotments'), value: 'allotment' },
              ]}
            />

            <KoobInput
              label={t('modal.allotmentsCount')}
              value={count}
              onChange={value => setCount(parseInt(value))}
              type="number"
              min="0"
              disabled={state !== 'allotment'}
            />
          </div>
        </div>

        <div>
          <KoobButton
            onClick={() => apply()}
            disabled={selectedOrganizations.length === 0}
          >
            {t('modal.applyButton', { count: selectedOrganizations.length })}
          </KoobButton>
        </div>
      </div>
    </KoobModal>
  );
}
