import KoobCard from '../../../components/Koob/KoobCard';
import KoobTitle from '../../../components/Koob/KoobTitle';
import { useTranslation } from 'react-i18next';
import KoobParagraph from '../../../components/Koob/KoobParagraph';
import { useAsync } from 'react-async';
import { getHotelAllotments, updateHotelAllotments } from '../../../api/node/hotels';
import moment from 'moment';
import { useEffect, useMemo, useRef, useState } from 'react';
import AllotmentsEditorMonthSelector, { AllotmentButton } from './AllotmentsEditorMonthSelector';
import AllotmentsEditorBody from './AllotmentsEditorBody';
import { Form, Formik } from 'formik';
import AllotmentsEditorLegend from './AllotmentsEditorLegend';
import { useSnack } from '../../../hooks';
import { mergePreviousAndNewValues, removeUntouchedValues } from './allotmentsUtils';

export default function AllotmentsEditor({ hotel }) {
  const { t } = useTranslation('allotments');
  const { notify } = useSnack();
  const formRef = useRef(null);

  const [previousHotelId, setPreviousHotelId] = useState(null);
  const [currentMonth, setCurrentMonth] = useState(moment().month());
  const [selectedDays, setSelectedDays] = useState([]);

  const [submitting, setSubmitting] = useState(false);

  const { data, isLoading, reload } = useAsync({
    promiseFn: getHotelAllotments,
    hotelId: hotel.id,
    from: moment().month(currentMonth).startOf('month').format('YYYY-MM-DD'),
    to: moment().month(currentMonth).endOf('month').format('YYYY-MM-DD'),
    watchFn: (prevProps, nextProps) => {
      return prevProps.from !== nextProps.from
        || prevProps.to !== nextProps.to
        || prevProps.hotelId !== nextProps.hotelId;
    }
  });
  const initialValues = data?.data;

  useEffect(() => {
    if (initialValues) {
      if (!previousHotelId || previousHotelId !== hotel.id) {
        resetForm();
        setPreviousHotelId(hotel.id);
      } else {
        formRef.current.setValues(mergePreviousAndNewValues(formRef.current.values, initialValues));
      }
    }
  }, [initialValues]);

  const daysInMonth = useMemo(() => {
    return moment().month(currentMonth).daysInMonth();
  }, [currentMonth]);

  const submitForm = (values) => {
    setSubmitting(true);

    const filteredValues = removeUntouchedValues(values);

    updateHotelAllotments({
      hotelId: hotel.id,
      payload: filteredValues
    })
      .then(() => {
        notify(t('editor.saveSuccess'));
      })
      .finally(() => {
        setSubmitting(false);
        reload();
      });
  };

  const resetForm = () => {
    formRef.current.setValues(initialValues);
    setSelectedDays([]);
  };

  return (
    <KoobCard>
      <KoobTitle>{t('editor.title')}</KoobTitle>
      <KoobParagraph>
        {t('editor.description')}
        <br/>
        {t('editor.changeMonthWarning')}
      </KoobParagraph>

      <div className="mt-5 flow-root overflow-hidden">
        <AllotmentsEditorMonthSelector
          currentMonth={currentMonth}
          setCurrentMonth={setCurrentMonth}
          reload={reload}
        />

        <Formik
          initialValues={initialValues}
          onSubmit={(values) => submitForm(values)}
          innerRef={formRef}
        >
          <Form>
            <AllotmentsEditorBody
              initialValues={initialValues}
              loading={isLoading}
              currentMonth={currentMonth}
              daysInMonth={daysInMonth}
              selectedDays={selectedDays}
              setSelectedDays={setSelectedDays}
            />

            <div className="mt-3 flex justify-between">
              <AllotmentButton
                onClick={() => resetForm()}
                disabled={isLoading || submitting}
              >
                <i className="far fa-refresh mr-1.5"/>
                {t('misc:reset')}
              </AllotmentButton>

              <AllotmentButton
                type="submit"
                disabled={isLoading || submitting}
              >
                {t('misc:save')}
                <i className="far fa-save ml-1.5"/>
              </AllotmentButton>
            </div>

            <div className="mt-3">
              <AllotmentsEditorLegend/>
            </div>
          </Form>
        </Formik>
      </div>
    </KoobCard>
  );
}
