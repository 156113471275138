import { useTranslation } from 'react-i18next';
import BulkEditor from './BulkEditor';
import LocationAwareSearch from '../../../../components/LocationAwareSearch';
import React, { useState } from 'react';
import { useSearchParams } from '../../../../hooks';
import { useAsync } from 'react-async';
import { getTripTemplates } from '../../../../api/node/trip';
import { isEqual } from 'lodash';
import { SegmentedControl } from '@koob/margaret';

export default function BulkEditorTemplates() {
  const { t } = useTranslation('toConnectionRequest');
  const [searchParams] = useSearchParams();

  const [onlyFavorites, setOnlyFavorites] = useState(false);

  const requestFilters = {
    search: searchParams.search ?? '',
    onlyFavorites,
    perPage: 100,
  };

  const { data, isLoading: loading } = useAsync({
    promiseFn: getTripTemplates,
    filters: requestFilters,
    watchFn: (props, prevProps) => {
      return !isEqual(props.filters, prevProps.filters);
    },
  });

  return (
    <BulkEditor
      title={t('bulkEdit.editor.templatesTitle')}
      filters={
        <>
          <LocationAwareSearch name="search" />

          <SegmentedControl
            options={[
              { label: t('experiences:state.all'), value: false },
              { label: t('tripDesigner:search.onlyFavorites'), value: true },
            ]}
            value={requestFilters.onlyFavorites ?? null}
            onSelect={value => setOnlyFavorites(value)}
          />
        </>
      }
      loading={loading}
      results={
        data?.data?.map(template => ({
          id: template.id,
          name: template.name,
          description: template.description,
          image: template.imageUrl,
        })) ?? []
      }
      type="template"
    />
  );
}
